// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

body {
    background-size:cover;
}

.imageCycle1{
  background:url(/assets/images/background/pexels-eberhard-grossgasteiger-572897.jpg);
  background-repeat:no-repeat;
  background-size:cover;
  -webkit-background-size: cover;
  -moz-background-size:cover;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-eberhard-grossgasteiger-572897.jpg',sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-eberhard-grossgasteiger-572897.jpg',sizingMethod='scale')";
}

.imageCycle2{
  background:url(/assets/images/background/pexels-pixabay-247474.jpg);
  background-repeat:no-repeat;
  background-size:cover;
  -webkit-background-size: cover;
  -moz-background-size:cover;
  filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-pixabay-247474.jpg',sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-pixabay-247474.jpg',sizingMethod='scale')";
}

.imageCycle3{
  background:url(/assets/images/background/pexels-pixabay-414122.jpg);
  background-repeat:no-repeat;
  background-size:cover;
  -webkit-background-size: cover;
  -moz-background-size:cover;
  filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-pixabay-414122.jpg',sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-pixabay-414122.jpg',sizingMethod='scale')";
}

.imageCycle4{
  background:url(/assets/images/background/pexels-pixabay-414513.jpg);
  background-repeat:no-repeat;
  background-size:cover;
  -webkit-background-size: cover;
  -moz-background-size:cover;
  filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-pixabay-414513.jpg',sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-pixabay-414513.jpg',sizingMethod='scale')";
}

.imageCycle5{
  background:url(/assets/images/background/pexels-errin-casano-2356059.jpg);
  background-repeat:no-repeat;
  background-size:cover;
  -webkit-background-size: cover;
  -moz-background-size:cover;
  filter:progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-errin-casano-2356059.jpg',sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background/pexels-errin-casano-2356059.jpg',sizingMethod='scale')";
}